import moment from 'moment'

import store from '@/store'
import router from '@/router'

import { keys } from '@/functions/localStorage'
import { getAsInt } from '@/functions/env'
import { createEventFromCode } from '@/functions/events'
import { is_hash_exists_ss_for_user, is_hash_exists_ss_for_webauthn } from '@/functions/auth'

const { userActivity } = keys

const VUE_APP_INACTIVE_TIME_TO_LOGOUT = getAsInt('VUE_APP_INACTIVE_TIME_TO_LOGOUT')

export const check_and_logout_inactive_user = async () => {
    if(!VUE_APP_INACTIVE_TIME_TO_LOGOUT) return false
    
    // is logged in
    if(is_hash_exists_ss_for_user() || is_hash_exists_ss_for_webauthn()){
        if(check_user_inactivity()){
            console.warn(`[check_and_logout_inactive_user] wylogowywanie`)
        
            await createEventFromCode('user-logout-not-active-user')
            await store.dispatch('CurrentUser/logout')
            
            if(router.currentRoute.name !== 'login')
                router.push({ name: 'login' })
                
            return true
        }
    }
}

export const check_user_inactivity = () => {
    if(!VUE_APP_INACTIVE_TIME_TO_LOGOUT) return false

    const lastActivityTime = get_user_activity()
    
    if(!lastActivityTime) return false
    
    const currentTime = Date.now();
    const inactivityDuration = currentTime - lastActivityTime;

    return inactivityDuration >= VUE_APP_INACTIVE_TIME_TO_LOGOUT;
}

export const set_user_activity = () => {
    return localStorage.setItem(userActivity, new Date().getTime())
}

const get_user_activity = () => {
    return localStorage.getItem(userActivity)
}
 
export const get_user_inactive_time_humanized = () => {
    const lastActivityTime = get_user_activity()
    
    if(!lastActivityTime) return null
    
    const currentTime = Date.now();
    const inactivityDuration = currentTime - lastActivityTime;

    return moment.duration(inactivityDuration).humanize()
}

export const check_user_inactivity_interval = () => {
    const intervalId = setInterval(async () => {
        const shouldLogout = await check_and_logout_inactive_user()
        if (shouldLogout) {
            clearInterval(intervalId)
        }
    }, 1000);
}