import { couch_uuid, id } from './fields'

export const schema = {
	title: 'stay note person schema',
	description: 'describes a single stay note person',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		stay_note_uuid: {
			type: 'string',
			ref: 'stay_notes',
		},
		person_id_ref: {
			...id,
		},
	},
	required: ['couch_uuid'],
}

export default schema
