import { aes, md5 } from '@/functions/encryption'

import moment from 'moment'

import { createEventFromCode } from '@/functions/events'

import { $classes } from '@/main'

import { isConnectionReady } from '@/functions/network'
import { getCircularReplacer } from '@/functions/json'
import {
	isQuotaExceededError,
	quotaExceededErrorResolution,
} from '@/functions/error'
import { keys } from '@/functions/localStorage'
import { getAsBool } from '@/functions/env'

let _getTokenPromise = null,
	_renewTokenPromise = null

const VUE_APP_MODULE_SHARE_DB_ENABLED = getAsBool(
	'VUE_APP_MODULE_SHARE_DB_ENABLED'
), VUE_APP_MODULE_WEBAUTHN_ENABLED = getAsBool(
    'VUE_APP_MODULE_WEBAUTHN_ENABLED'
)

const {
	user,
	userPassHashKey,
	userPassHashDoubleKey,
	passwordEncryptedByUserKey,
	authEncryptedByUser,
} = keys

const getItem = (storage, key) => storage.getItem(key)
const setItem = (storage, key, value) => storage.setItem(key, value)
const removeItem = (storage, key) => storage.removeItem(key)

// auth online per user
export const key = user

const _getToken = async () => {
	const auth = getAuth()
	if (!auth) return

	const { access_token_expire } = auth
	if (Date.now() < access_token_expire) {
		console.debug(
			`[getToken] access_token wygasa: ${moment(
				access_token_expire
			).format('YYYY-MM-DD HH:mm')}`
		)
		return auth?.access_token
	}

	const refresh_token = await getRefreshToken()
	if (refresh_token) {
		return renewToken(refresh_token)
	}
}

export const getToken = async () => {
	if (_getTokenPromise) return await _getTokenPromise
	_getTokenPromise = _getToken()
	const access_token = await _getTokenPromise
	_getTokenPromise = null
	return access_token
}

const isClassesReady = async () => {
	console.debug(`[isClassesReady] Getting public classes...`)
	try {
		await $classes.loadPublic()
	} catch (e) {
		await createEventFromCode('fetch-classes-public-fail')
		throw e
	}
}

export const getTokenBy = async (data, grant_type = 'password') => {
	console.debug(`[getTokenBy] starting...`)
	if (grant_type === 'password') {
		const { login, pass } = data
		await isConnectionReady()
		await isClassesReady()
		return await $classes.AuthService.token({
			grant_type: 'password',
			username: login,
			password: pass,
			scope: 'provider:Person',
		})
	}
	return null
}

const getRefreshToken = async () => {
	const auth = getAuth()
	if (!auth) return

	const { refresh_token_expire } = auth
	if (Date.now() < refresh_token_expire) {
		console.debug(
			`[getToken] refresh_token wygasa: ${moment(
				refresh_token_expire
			).format('YYYY-MM-DD HH:mm')}`
		)
		return auth?.refresh_token
	} else {
		await createEventFromCode('auth-refresh-token-expired')
	}
}

const _renewToken = async (refresh_token_old) => {
	console.debug(`[renewToken] starting...`)
	await isConnectionReady()
	await isClassesReady()

	try {
		const { access_token, refresh_token, expires_in, refresh_expires_in } =
			await $classes.AuthService.token({
				grant_type: 'refresh_token',
				refresh_token: refresh_token_old,
				scope: 'provider:Person',
			})

		setAuth({ access_token, refresh_token, expires_in, refresh_expires_in })
		await createEventFromCode('auth-refresh-token-renew-success')
		return access_token
	} catch (e) {
		await createEventFromCode('auth-refresh-token-renew-fail')
	}
}

export const renewToken = async (refresh_token_old) => {
	if (_renewTokenPromise) return await _renewTokenPromise
	_renewTokenPromise = _renewToken(refresh_token_old)
	const access_token = await _renewTokenPromise
	_renewTokenPromise = null
	return access_token
}

const getAuth = () => {
	const info = getUser()
	return info?.auth
}

const getUser = () => {
	const userData = getItem(localStorage, key)
	return userData ? JSON.parse(userData) : null
}

export const getUserLogin = () => {
	const user = getUser()
	return user?.instance?.login || '-'
}

export const setAuth = (auth) => {
	console.debug(`[setAuth] saving...`)
	const dataStringified = createAuthObject(auth)
	try {
		setItem(localStorage, key, dataStringified)
	} catch (e) {
		if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
		else throw e
	}
}

export const is_auth_not_expired = () => {
	const auth = getAuth()
	return auth?.refresh_token_expire >= Date.now()
}

// auth offline per user
export const encryptData = (data, baseForHash) => {
	console.debug(`[encryptData]`)
	const hash = md5.encrypt(baseForHash)
	return aes.encrypt(data, hash)
}

export const decryptData = (data, baseForHash) => {
	console.debug(`[decryptData]`)
	const hash = md5.encrypt(baseForHash)
	return aes.decrypt(data, hash)
}

export const createAuthObject = (auth) => {
	const now = Date.now()
	const access_token_expire = now + auth.expires_in * 1000
	const refresh_token_expire = now + auth.refresh_expires_in * 1000

	const info = getUser()
	const infoNew = {
		...info,
		auth: {
			...auth,
			access_token_expire,
			access_token_created: now,
			refresh_token_expire,
			refresh_token_created: now,
		},
	}
	return JSON.stringify(infoNew, getCircularReplacer())
}

export const setEncryptedAuth = (auth, userLoginData) => { // DEPRECATED
	console.debug(`[setEncryptedAuth] saving...`)

	const dataEncrypted = encryptData(JSON.stringify(auth), userLoginData)

	try {
		setItem(localStorage, authEncryptedByUser, dataEncrypted)
	} catch (e) {
		if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
		else throw e
	}
}

export const getDecryptedAuth = (userLoginData) => {  // DEPRECATED
	const encryptedData = getItem(localStorage, authEncryptedByUser)
	if (!encryptedData) return null

	return JSON.parse(decryptData(encryptedData, userLoginData))
}

const { authEncryptedByCredentialId } = keys

export const setEncryptedAuthV2 = (auth, {
    login, pass, credentialId
}={}) => {
	let secret = null, key = null
	
	if(credentialId) {
	    secret = credentialId
	    key = authEncryptedByCredentialId
	} else if(login && pass) {
	    secret = `${login}${pass}`
	    key = authEncryptedByUser
	} else
	    throw new Error(`[setEncryptedAuthV2] no secret for encryption OR key`)

    console.debug(`[setEncryptedAuthV2] saving...`)
    
	const dataEncrypted = encryptData(JSON.stringify(auth), secret)

	try {
		setItem(localStorage, key, dataEncrypted)
	} catch (e) {
		if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
		else throw e
	}
}

export const getDecryptedAuthV2 = ({
    login, pass, credentialId
}={}) => {
    let secret = null, key = null
	
	if(credentialId) {
	    secret = credentialId
	    key = authEncryptedByCredentialId
	} else if(login && pass) {
	    secret = `${login}${pass}`
	    key = authEncryptedByUser
	} else
	    throw new Error(`[getDecryptedAuthV2] no secret for encryption OR key`)
	    
	const encryptedData = getItem(localStorage, key)
	if (!encryptedData) return null

	return JSON.parse(decryptData(encryptedData, secret))
}

// rxdb per user
const { passwordEncryptedBySaHashKey, saHashDoubleKey } = keys

export const is_hash_exists_for_user = () => !!getItem(localStorage, userPassHashDoubleKey)

export const is_hash_exists_ss_for_user = () =>
	!!getItem(sessionStorage, userPassHashKey)

export const compare_password_for_user = ({ userPassPlain, userEmailPlain }) => {
	if (!is_hash_exists_for_user()) return null

	const userPassHash = generate_user_hash({ userPassPlain, userEmailPlain })
	const userPassHashDouble = getItem(localStorage, userPassHashDoubleKey)

	if (!userPassHash || !userPassHashDouble) {
		console.error(`[compare_password_for_user] not enough data`)
		return null
	}

	return md5.encrypt(userPassHash) === userPassHashDouble
}

export const set_RxDB_auth_offline = ({ userPassPlain, userEmailPlain }) => {
	if (!userPassPlain || !userEmailPlain) {
		console.debug(`[setRxDbAuthOffline] no password of user or user login`)
		return
	}

	const userPassHash = generate_user_hash({ userPassPlain, userEmailPlain })

	if (!userPassHash)
		throw new Error(`[setRxDbAuthOffline] userPassHash is missing`)

	console.debug(`[setRxDbAuthOffline] saving rxdb auth config data`)

	try {
		setItem(sessionStorage, userPassHashKey, userPassHash)
	} catch (e) {
		if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
		else throw e
	}
}

const decrypt_rxdb_password_for_user = () => {
	const rxdbPasswordEncrypted = getItem(
		localStorage,
		passwordEncryptedByUserKey
	)
	const userPassHash = getItem(sessionStorage, userPassHashKey)
	const userPassHashDouble = getItem(localStorage, userPassHashDoubleKey)

	if (
		rxdbPasswordEncrypted &&
		userPassHashDouble &&
		userPassHash &&
		md5.encrypt(userPassHash) === userPassHashDouble
	) {
		return aes.decrypt(rxdbPasswordEncrypted, userPassHash)
	}
}

export const clear_auth_data = () => {
    removeItem(sessionStorage, userPassHashKey)
    removeItem(sessionStorage, saHashDoubleKey)
    removeItem(sessionStorage, credentialIdHashKey)
}

/* 
   parametry: 
   - userPassPlain, userEmailPlain - dane logowania usera
   - rxdbPasswordPlain - hasło do bazy danych lokalnej (rxdb) pobierane z backendu
    
    1. generujemy hash użytkownika na podstawie danych logowania - zapisywany jest w sesji
    2. generujemy drugi hash z pierwszego - zapisywany jest w local storage
    3. szyfrujemy hasło do bazy danych lokalnej (rxdb) na podstawie pierwszego hasha - zapisywane jest w local storage
*/
const set_RxDB_auth_for_user = ({
	userPassPlain,
	userEmailPlain,
	rxdbPasswordPlain,
}) => {
	if (!userPassPlain || !rxdbPasswordPlain || !userEmailPlain) {
		console.debug(`[setRxDbAuth] no password of user or rxdb or user login`)
		return
	}

	console.debug(`[setRxDbAuth] saving rxdb auth config data`)

	try {
		const userPassHash = generate_user_hash({ userPassPlain, userEmailPlain })
		const userPassHashDouble = md5.encrypt(userPassHash)
		const rxdbPasswordEncrypted = aes.encrypt(
			rxdbPasswordPlain,
			userPassHash
		)

		try {
			setItem(
				localStorage,
				passwordEncryptedByUserKey,
				rxdbPasswordEncrypted
			)
			setItem(localStorage, userPassHashDoubleKey, userPassHashDouble)
			setItem(sessionStorage, userPassHashKey, userPassHash)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	} catch (error) {
		console.debug(error)
	}
}

// rxdb
// export const is_offline_login_avaliable = is_hash_exists_for_user

export const set_RxDB_auth = ({
	userPassPlain,
	userEmailPlain,
	rxdbPasswordPlain,
	saHash,
}) => {
	set_RxDB_auth_for_user({ userPassPlain, userEmailPlain, rxdbPasswordPlain })
	set_RxDB_auth_for_sa({ rxdbPasswordPlain, saHash })
}

export const decrypt_rxdb_password = () => {
	console.debug(`[decrypt_rxdb_password] starting...`)
	
	const pass = decrypt_rxdb_password_for_user() || decrypt_rxdb_password_for_webauthn() || decrypt_rxdb_password_for_sa() || null
	
	if(pass)
	    console.debug(`[decrypt_rxdb_password] pass found`)
	
	return pass
}

const generate_user_hash = ({ userPassPlain, userEmailPlain, saHash, credentialId }) => {
	if (userPassPlain && userEmailPlain)
		return md5.encrypt(`${userPassPlain}${userEmailPlain}`)
	if (saHash) 
	    return md5.encrypt(`${saHash}`)
	if (credentialId)
	    return md5.encrypt(`${credentialId}`)
	console.error(
		`[generate_user_hash] user access data no complete. (userPassPlain: ${
			userPassPlain ? 'true' : 'false'
		}, userEmailPlain: ${userEmailPlain ? 'true' : 'false'}, saHash: ${
			saHash ? 'true' : 'false'
		})`
	)
}

// rxdb shared auth online
const { saHashTripleKey } = keys

export const is_hash_exists_for_sa = () => !!getItem(localStorage, saHashTripleKey)

/* 
    1. pobieramy z local storage zaszyfrowane hasło do bazy danych lokalnej (rxdb)
    2. pobieramy z local storage drugi hash
    3. pobieramy z sesji pierwszy hash
    4. sprawdzamy czy drugi hash jest zgodny z pierwszym
    5. deszyfrujemy hasło do bazy danych lokalnej (rxdb) na podstawie pierwszego hasha    
*/
const decrypt_rxdb_password_for_sa = () => {
	if (!VUE_APP_MODULE_SHARE_DB_ENABLED) return null

	const rxdbPasswordEncrypted = getItem(
		localStorage,
		passwordEncryptedBySaHashKey
	)
	const saHashTriple = getItem(localStorage, saHashTripleKey)
	const saHashDouble = getItem(sessionStorage, saHashDoubleKey)

	if (
		rxdbPasswordEncrypted &&
		saHashTriple &&
		saHashDouble &&
		md5.encrypt(saHashDouble) === saHashTriple
	) {
		return aes.decrypt(rxdbPasswordEncrypted, saHashDouble)
	}
}

/* 
   parametry: 
   - saHash - hash SA z user.gui_permissions_hash pobierane z backendu
   - rxdbPasswordPlain - hasło do bazy danych lokalnej (rxdb) pobierane z backendu
    
    1. generujemy hash użytkownika na podstawie hasha gui_permissions_hash - zapisywany jest w sesji
    2. generujemy drugi hash z pierwszego - zapisywany jest w local storage
    3. szyfrujemy hasło do bazy danych lokalnej (rxdb) na podstawie pierwszego hasha - zapisywane jest w local storage
*/

const set_RxDB_auth_for_sa = ({ saHash, rxdbPasswordPlain }) => {
	if (!VUE_APP_MODULE_SHARE_DB_ENABLED) return

	if (!saHash) {
		console.debug(`[set_RxDB_auth_for_sa] no hash`)
		return
	}

	console.debug(`[set_RxDB_auth_for_sa] saving rxdb auth config data`)

	try {
		const saHashDouble = generate_user_hash({ saHash })
		const saHashTriple = md5.encrypt(saHashDouble)
		const rxdbPasswordEncrypted = aes.encrypt(
			rxdbPasswordPlain,
			saHashDouble
		)

		try {
			setItem(
				localStorage,
				passwordEncryptedBySaHashKey,
				rxdbPasswordEncrypted
			)
			setItem(localStorage, saHashTripleKey, saHashTriple)
			setItem(sessionStorage, saHashDoubleKey, saHashDouble)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	} catch (error) {
		console.debug(error)
	}
}

/* 
    parametry:
    - saHash - hash SA z user.gui_permissions_hash pobierane z backendu
    
    1. hashujemy hasło SA
    2. pobieramy z local storage drugi hash
    3. porównujemy drugi hash z zahaszowanym hasłem SA
*/
export const compare_password_for_sa = ({ saHash }) => {
	if (!is_hash_exists_for_sa()) return null

	const saHashDouble = generate_user_hash({ saHash })
	const saHashTriple = getItem(localStorage, saHashTripleKey)

	if (!saHashDouble || !saHashTriple) {
		console.error(`[compare_password_for_sa] not enough data`)
		return null
	}

	return md5.encrypt(saHashDouble) === saHashTriple
}

// webauthn

const { credentialIdHashKey,  credentialIdHashDoubleKey, passwordEncryptedByCredentalIdKey } = keys

export const is_hash_exists_for_webauthn = () => !!getItem(localStorage, credentialIdHashDoubleKey)

export const is_hash_exists_ss_for_webauthn = () =>
	!!getItem(sessionStorage, credentialIdHashKey)

export const set_RxDB_auth_for_webauthn = ({ rxdbPasswordPlain, credentialId }) => {
	if (!VUE_APP_MODULE_WEBAUTHN_ENABLED) return

	if (!credentialId) {
		console.debug(`[set_RxDB_auth_for_webauthn] no data`)
		return
	}

	console.debug(`[set_RxDB_auth_for_webauthn] saving rxdb auth config data`)

    
	try {
		const credentialIdHash = generate_user_hash({ credentialId })
		const credentialIdHashDouble = md5.encrypt(credentialIdHash)
		const rxdbPasswordEncrypted = aes.encrypt(
			rxdbPasswordPlain,
			credentialIdHash
		)

		try {
			setItem(
				localStorage,
				passwordEncryptedByCredentalIdKey,
				rxdbPasswordEncrypted
			)
			setItem(localStorage, credentialIdHashDoubleKey, credentialIdHashDouble)
			setItem(sessionStorage, credentialIdHashKey, credentialIdHash)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	} catch (error) {
		console.debug(error)
	}
    
}

const decrypt_rxdb_password_for_webauthn = () => {
	if (!VUE_APP_MODULE_WEBAUTHN_ENABLED) return null

	const rxdbPasswordEncrypted = getItem(
		localStorage,
		passwordEncryptedByCredentalIdKey
	)
	const credentialIdHashDouble = getItem(localStorage, credentialIdHashDoubleKey)
	const credentialIdHash = getItem(sessionStorage, credentialIdHashKey)

	if (
		rxdbPasswordEncrypted &&
		credentialIdHashDouble &&
		credentialIdHash &&
		md5.encrypt(credentialIdHash) === credentialIdHashDouble
	) {
		return aes.decrypt(rxdbPasswordEncrypted, credentialIdHash)
	}
}

export const compare_password_for_webauthn = ({ credentialId }) => {
	if (!is_hash_exists_for_webauthn()) return null

	const credentialIdHash = generate_user_hash({ credentialId })
	const credentialIdHashDouble = getItem(localStorage, credentialIdHashDoubleKey)

	if (!credentialIdHash || !credentialIdHashDouble) {
		console.error(`[compare_password_for_webauthn] not enough data`)
		return null
	}

	return md5.encrypt(credentialIdHash) === credentialIdHashDouble
}

export const set_RxDB_auth_offline_for_webauthn = ({ credentialId }) => {
	if (!credentialId) {
		console.debug(`[set_RxDB_auth_offline_for_webauthn] no password of user or user login`)
		return
	}

	const credentialIdHash = generate_user_hash({ credentialId })

	if (!credentialIdHash)
		throw new Error(`[set_RxDB_auth_offline_for_webauthn] credentialIdHash is missing`)

	console.debug(`[set_RxDB_auth_offline_for_webauthn] saving rxdb auth config data`)

	try {
		setItem(sessionStorage, credentialIdHashKey, credentialIdHash)
	} catch (e) {
		if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
		else throw e
	}
}

export const clear_data_for_webauth = () => {
    removeItem(sessionStorage, credentialIdHashKey)
    removeItem(localStorage, credentialIdHashDoubleKey)
    removeItem(localStorage, passwordEncryptedByCredentalIdKey)
}

export const clear_data_for_user = () => {
    removeItem(sessionStorage, userPassHashKey)
    removeItem(localStorage, userPassHashDoubleKey)
    removeItem(localStorage, passwordEncryptedByUserKey)
}

/* 
    README
    
    nowe dane do autoryzacji [offline] (np. webauthn id)
    
    zapis danych do autoryzacji
    1. na podobieństwo set_RxDB_auth_for_sa({ rxdbPasswordPlain, saHash }) dodajemy nową metodę
    - generujemy hash dla naszych nowych danych do autoryzacji
    - generujemy kolejny hash na podstawie pierwszego
    - hashem szyfrujemy hasło do bazy danych lokalnej (rxdb)
    - zapisujemy 2 hash i zaszyfrowane hasło do db w local storage
    - zapisujemy 1 hash w sesji
    
    odszyfrowywanie db [dodajemny do decrypt_rxdb_password]
    2. na podobieństwo decrypt_rxdb_password_for_sa dodajemy nową metodę
    - pobieramy z local storage zaszyfrowane hasło do bazy danych lokalnej (rxdb)
    - pobieramy z local storage drugi hash
    - pobieramy z sesji pierwszy hash
    - sprawdzamy czy drugi hash jest zgodny z pierwszym
    - deszyfrujemy hasło do bazy danych lokalnej (rxdb) na podstawie pierwszego hasha
    - zwracamy zdeszyfrowane hasło
    
    porównywanie danych przy autoryzacji [do uzycia w storze]
    3. na podobieństwo compare_password_for_sa dodajemy nową metodę
    - hashujemy nowe dane do autoryzacji
    - pobieramy z local storage drugi hash
    - porównujemy drugi hash z zahaszowanym hasłem
    - zwracamy wynik porównania
*/

export default getToken
