export const webAuthnIsSupported = function () {
    console.debug(`[webAuthnIsSupported]`)
    if (!window.PublicKeyCredential || !navigator.credentials) {
        console.warn("WebAuthn nie jest obsługiwane w tej przeglądarce.");
    } else {
        console.log("WebAuthn jest dostępny.");
    }
    
    return true
}

export const webAuthnRegisterFingerprint = async function (user={}) {
    if(!webAuthnIsSupported())
        return null


    console.debug(`[webAuthnRegisterFingerprint] starting...`)
    const { login, first_name, last_name, id } = user
    const encoder = new TextEncoder();
    const userId = encoder.encode(id);
    
    if (userId.length < 1 || userId.length > 64) {
        throw new Error('The length of user.id must be between 1-64 bytes.');
    }
    
    const options = {
        publicKey: {
            challenge: Uint8Array.from("random-challenge", c => c.charCodeAt(0)),
            rp: { name: 'HosPanel' },
            user: {
                id: userId,
                name: login,
                displayName: [first_name, last_name]
                    .filter(Boolean)
                    .join(' ')
            },
            pubKeyCredParams: [{ alg: -7, type: "public-key" }, { type: "public-key", alg: -257 }],
            authenticatorSelection: {
                authenticatorAttachment: "platform",
                userVerification: "required"
            },
            timeout: 60000,
            attestation: "direct"
        }
    };
    
    const credential = await navigator.credentials.create(options)
    
    console.debug(`[webAuthnRegisterFingerprint] credential:`, credential)
    
    //return base64Encode(String.fromCharCode(new Uint8Array(credential.rawId)))
    
    return credential.id
    
    // webAuthnCredentialSave(credential)
}

// eslint-disable-next-line no-unused-vars
export const webAuthnAuthenticate = async function(user={}) {
    console.debug(`[webAuthnAuthenticate] starting...`)
    
    console.debug(`[webAuthnRegisterFingerprint] starting...`)
    
    const options = {
        publicKey: {
            challenge: Uint8Array.from("random-challenge", c => c.charCodeAt(0)),
            // allowCredentials: [{
            //     id: storedCredentialId,
            //     type: "public-key"
            // }],
            timeout: 60000,
            userVerification: "required",
        }
    };

    try {
        const credential = await navigator.credentials.get(options);
        
        // console.debug(`[webAuthnAuthenticate] credential:`, credential)
        console.log("[webAuthnAuthenticate] finished");
        
        return credential.id
    
        // return base64Encode(String.fromCharCode(new Uint8Array(credential.rawId)))
        
    } catch (e) {
        console.warn("[webAuthnAuthenticate] Błąd uwierzytelniania:", e)
        console.error(e)
    }
}

// DEPRECATED

// const webAuthnCredentialSave = function(credential) {
//     console.debug(`[webAuthnCredentialSave]`, credential)
//     localStorage.setItem('webAuthnCredential', base64Encode(String.fromCharCode(...new Uint8Array(credential.rawId))));
// }

// const webAuthnCredentialGet = function() {
//     console.debug(`[webAuthnCredentialGet]`)
//     const storedCredentialId = Uint8Array.from(base64Decode(localStorage.getItem('webAuthnCredential')), c => c.charCodeAt(0));

//     return storedCredentialId
// }

// function base64Encode(str) {
//     return btoa(unescape(encodeURIComponent(str)));
// }

// function base64Decode(str) {
//     return decodeURIComponent(escape(atob(str)));
// }
