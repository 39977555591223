import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import { $classes } from '@/main'
// import { translate } from '@/i18n'

import { createEventFromCode, createEvent } from '@/functions/events'
import { isOnline } from '@/functions/network'
import { setAuth, getTokenBy, setEncryptedAuthV2, getDecryptedAuthV2 } from '@/functions/auth'
import { sendPendingReports } from '@/functions/reportProblem'
import { isQuotaExceededError, quotaExceededErrorResolution, sanitizeError } from '@/functions/error'
import { keys } from '@/functions/localStorage'
import {
	getToken,
	set_RxDB_auth,
	compare_password_for_user,
	clear_auth_data,
	set_RxDB_auth_offline,
	compare_password_for_sa,
	getUserLogin,
	set_RxDB_auth_for_webauthn,
	set_RxDB_auth_offline_for_webauthn,
	compare_password_for_webauthn,
	clear_data_for_webauth,
	clear_data_for_user,
	is_hash_exists_for_webauthn,
} from '@/functions/auth'
import { removeCalculatedParams } from '@/functions/paramsOffline'
import { getCircularReplacer } from '@/functions/json'
import { isEnvDevelopment } from '@/functions/env'
import { webAuthnRegisterFingerprint, webAuthnAuthenticate } from '@/functions/webAuthn'

import DatabaseService from '@/services/Database.service.js'
import STTService from '@/services/STT.service.js'

import { getAsBool } from '@/functions/env'
const VUE_APP_MODULE_WEBAUTHN_ENABLED = getAsBool('VUE_APP_MODULE_WEBAUTHN_ENABLED')

@Module({ namespaced: true })
class CurrentUser extends VuexModule {
	instance = null
	sms_request_id = null
	onesignal_skd_initialized = false
	key = keys.user
	network_status = 'online'
	logout_status = null
	
	get userRole() {
		return this.instance?.role
	}

	@Mutation
	setInstance(user) {
		this.instance = user
	}

	@Mutation
	setNetworkStatus(status) {
		this.network_status = status
	}
	
	@Mutation
	setLogoutStatus(status) {
		this.logout_status = status
	}

	@Mutation
	setOneSignalInitialized() {
		this.onesignal_skd_initialized = true
	}

	@Action({ rawError: true })
	async clearRxDB() {
		console.debug(`[clearRxDB] initializing remove database procedure...`)

		/* RxDB */
		await (await DatabaseService.singleton()).removeDatabase()

		console.debug(`[clearRxDB] finished`)
	}

	@Action({ rawError: true })
	async removeCalculatedParams() {
		removeCalculatedParams()
	}
	
	@Action({ rawError: true })
	async updateNetworkStatusWithError() {
		try {
			await isOnline()
			
			if(this.network_status === 'offline'){
				await createEventFromCode(
					'network-internet-connection-back-success'
				)
				await sendPendingReports()
			}
			
			this.context.commit('setNetworkStatus', 'online')
		} catch (e) {
			const wasOnline = this.network_status === 'online'
			
			if(wasOnline) {
				this.context.commit('setNetworkStatus', 'offline')
				throw e
			}
		}
	}

	@Action({ rawError: true })
	async updateNetworkStatus() {
		try {
			await this.context.dispatch('updateNetworkStatusWithError')
		} catch (e) {
			console.warn(e)
		}
	}

	@Mutation
	saveUserDataWithoutAuth(data) {
		const key = this.key

		let dataOld = localStorage.getItem(key)
				? JSON.parse(localStorage.getItem(key))
				: {},
			dataNew = {
				...dataOld,
				...data,
				last_update: new Date().toLocaleTimeString('pl-PL'),
			}

		let dataStringified = JSON.stringify(dataNew, getCircularReplacer())

		/* localstorage */
		try {
			localStorage.setItem(key, dataStringified)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	}

	@Mutation
	saveUserData(data) {
		const key = this.key

		let { auth } = data,
			{
				access_token,
				refresh_token,
				expires_in,
				refresh_expires_in,
				// remember_me,
			} = auth,
			access_token_expire = Date.now() + expires_in,
			access_token_created = Date.now(),
			refresh_token_expire = Date.now() + refresh_expires_in,
			refresh_token_created = Date.now()

		let dataOld = JSON.parse(localStorage.getItem(key) || '{}'),
			dataNew = {
				...dataOld,
				...data,
				auth: {
					...data.auth,

					access_token,
					refresh_token,
					access_token_expire,
					access_token_created,
					refresh_token_expire,
					refresh_token_created,
				},
				last_update: new Date().toLocaleTimeString('pl-PL'),
			}

		let dataStringified = JSON.stringify(dataNew, getCircularReplacer())

		/* localstorage */
		try {
			localStorage.setItem(key, dataStringified)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	}

	@Mutation
	clearUserData() {
		// delete only auth
		if (localStorage.getItem(this.key)) {
			const dataOld = JSON.parse(localStorage.getItem(this.key)),
				dataNew = {
					instance: dataOld.instance,
					last_update: dataOld.last_update,
				}

			/* localstorage */
			try {
				localStorage.setItem(this.key, JSON.stringify(dataNew))
			} catch (e) {
				if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
				else throw e
			}
		}
		
		clear_auth_data()
	}

	@Mutation
	restoreUserData() {
		const key = this.key

		if (localStorage.getItem(key)) {
			let data = JSON.parse(localStorage.getItem(key))

			if (data?.instance?.id) {
				console.debug(
					`[restoreUserData] Restoring user data from cache`
				)
				this.instance = data.instance
			} else console.debug(`[restoreUserData] No user data in cache.`)

		}
	}

	@Action({ rawError: true })
	async requireUserInfo() {
		if (this.instance === null) {
			await this.context.dispatch('requirePublicClasses')

			console.debug(`[requireUserInfo] Getting current user instance...`)
			const user = await $classes?.Person?.current_user() //TODO add condition
			if (user?.__id) {
				this.context.commit('setInstance', user)
				await this.context.dispatch('requireAuthenticatedClasses')

				console.debug(`[requireUserInfo] success.`)
			} else {
				console.debug(`[requireUserInfo] fail.`)
			}
		}
	}

	@Action({ rawError: true })
	async requireAuthenticatedClasses() {
		const token = await getToken()

		if (token) {
			console.debug(
				`[requireAuthenticatedClasses] started`
			)
			await $classes.loadAuthenticated({ token: token })
			
			console.debug(
				`[requireAuthenticatedClasses] finished`
			)
		} else {
			console.debug(
				`[requireAuthenticatedClasses] no token`
			)
		}
	}

	@Action({ rawError: true })
	async requirePublicClasses() {
		if (!$classes?.Person) {
			console.debug(
				`[requirePublicClasses] started`
			)
			try {
				await $classes.loadPublic()
				console.debug(
					`[requirePublicClasses] finished`
				)
			} catch (e) {
				console.log({ ...e })
			}
		}
	}

	@Action({ rawError: true })
	async get_user_data_from_cache() {
		if (this.instance == null) {
			this.context.commit('restoreUserData')
		}
	}

	@Action({ rawError: true })
	async compare_offline_logged_user_to_online_access_data({ login, pass, saHash }) {
		console.debug(
			`[compare_offline_logged_user_to_online_access_data] checking data...`
		)
		
		const userPasswordCompare = compare_password_for_user({
				userPassPlain: pass,
				userEmailPlain: login,
			}),
			saPasswordCompare = compare_password_for_sa({ saHash })

		const initClearRxDB = userPasswordCompare === false && saPasswordCompare === false 
			
		const useForeignDB = userPasswordCompare === false && saPasswordCompare
		
		if(useForeignDB){
			await createEvent({
				status: 'Success',
				details: `Użytkownik posiada dostęp do bazy danych zsynchronizowanej przez ${getUserLogin()}`,
			})
			
			clear_data_for_webauth()
			clear_data_for_user()
		}
			

		if (initClearRxDB) {
			await this.context.dispatch('clearRxDB')

			//TODO reset repliactions
			await this.context.dispatch(
				'CurrentDatabase/resetReplicationInfo',
				null,
				{ root: true }
			)
		}
	}

	@Action({ rawError: true })
	async userDataUpdate() {
		const token = await getToken()

		if (token) {
			await $classes.loadAuthenticated({ token: token })
			const user = await $classes.Person.current_user()

			this.context.commit('saveUserDataWithoutAuth', {
				instance: user,
			})
			this.context.commit('setInstance', user)
		} else
			throw new Error(
				`Access token and refresh token expired, please login by online method.`
			)
	}

	@Action({ rawError: true })
	async login({ login, pass, remember_me, account_type }) {
		if(isEnvDevelopment)
			console.debug(login, remember_me, account_type)

		// czyszczenie niepotrzebnych danych
		this.context.dispatch('removeCalculatedParams')
		
		let token = null

		try {
			token = await getTokenBy({login, pass})
		} catch (error) {
			if (!error?.response) {
				throw new Error(
					`Login online method is out of service, please check internet access or login by offline method.`
				)
			} else {
				// createEvent({
				// 	status: 'Fail',
				// 	details: error?.response?.data?.error_description && translate(error?.response?.data?.error_description) || 'Nieznany błąd',
				// 	debug: sanitizeError(error),
				// })
				
				throw sanitizeError(error)
			}
		}

		if (token) {
			const {
				access_token,
			} = token

			await $classes.loadAuthenticated({ token: access_token })
			const user = await $classes.Person.current_user(),
				rxdb_config = await $classes.Person.get_rxdb_config(),
				{ gui_permissions_hash } = user

			this.context.commit('clearUserData')

			await this.context.dispatch(
				'compare_offline_logged_user_to_online_access_data',
				{ login, pass, saHash: gui_permissions_hash }
			)
			
			let rxdbPasswordPlain = rxdb_config?.rxdb_password
			
			set_RxDB_auth({
				userPassPlain: pass,
				userEmailPlain: login,
				rxdbPasswordPlain,
				
				//SA
				saHash: gui_permissions_hash
			})
			
			setAuth(token)
			setEncryptedAuthV2(token, {
				login, pass,
			})
			
			const userTransformed = {
				...user,
				login: user?.login || user?.ldap_login,
			}, instance = {
				id: userTransformed.id,
				login: userTransformed.login,
				role: userTransformed.role,
				first_name: userTransformed.first_name,
				last_name: userTransformed.last_name,
				ldap_login: userTransformed.ldap_login,
			}
			
			this.context.commit('saveUserDataWithoutAuth', {
				instance
			})
			
			this.context.commit('setInstance', instance)

			await createEventFromCode('user-login-online-success')
			
			if(VUE_APP_MODULE_WEBAUTHN_ENABLED && !is_hash_exists_for_webauthn()){
				try {
					const credentialId = await webAuthnRegisterFingerprint(instance)
					
					set_RxDB_auth_for_webauthn({ credentialId, rxdbPasswordPlain })
					
					setEncryptedAuthV2(token, {
						credentialId
					})
					
					await createEventFromCode('webauthn-register-success')
				} catch (e) {
					console.warn(e)
					console.warn('Failed to save fingerprint data.')
				}
			}
		}
	}

	@Action({ rawError: true })
	// eslint-disable-next-line no-unused-vars
	async login_offline({ login, pass }) {
		console.debug(`[login_offline] checking password...`)

		if (
			compare_password_for_user({
				userPassPlain: pass,
				userEmailPlain: login,
			}) === true
		) {
			console.debug(`[login_offline] password ok`)
			this.context.commit('restoreUserData')
			set_RxDB_auth_offline({ userPassPlain: pass, userEmailPlain: login })
			setAuth(getDecryptedAuthV2({
				login, pass,
			}))

			await createEventFromCode('user-login-offline-success')
		} else {
			await createEventFromCode('user-login-offline-fail')

			throw new TypeError(
				'Wrong password, please try again or login by online method.'
			)
		}
	}
	
	@Action({ rawError: true })
	// eslint-disable-next-line no-unused-vars
	async login_offline_webauthn() {
		console.debug(`[login_offline_webauthn] checking password...`)
		
		const credentialId = await webAuthnAuthenticate()
		
		if(!credentialId)
			throw new TypeError(
				'Failed to authenticate by fingerprint, please try again or login by other method.'
			)
			
		if(compare_password_for_webauthn({ credentialId }) === true){
			console.debug(`[login_offline_webauthn] password ok`)
			this.context.commit('restoreUserData')
			set_RxDB_auth_offline_for_webauthn({ credentialId })
			setAuth(getDecryptedAuthV2({
				credentialId,
			}))
			
			await createEventFromCode('webauthn-login-success')
		} else {
			await createEventFromCode('webauthn-login-fail')
			
			throw new TypeError(
				'Wrong password, please try again or login by other method.'
			)
		}
	}

	@Action({ rawError: true })
	async login_with_token() {
		console.debug(`[login_with_token] starting...`)

		const token = await getToken()

		if (token) {
			await $classes.loadAuthenticated({ token: token })
			const user = await $classes.Person.current_user()

			this.context.commit('saveUserDataWithoutAuth', {
				instance: user,
			})
			this.context.commit('setInstance', user)

			await createEventFromCode('user-login-online-success')

			console.debug(`[login_with_token] logged`)
		} else {
			console.debug(`[login_with_token] no token`)
		}
	}

	@Action({ rawError: true })
	async proxy_login({ accessToken }) {
		await $classes.loadAuthenticated({ token: accessToken })
		const user = await $classes.Person.current_user()

		this.context.commit('setInstance', user)
	}

	@Action({ rawError: true })
	async logout() {
		try {
			console.debug('[logout] initializing...')
			this.context.commit('setLogoutStatus', 'pending_online')
	
			if ($classes?.Person)
				try {
					await $classes.Person?.logout()
				} catch (e) {
					console.warn(e)
				}
			
			this.context.commit('setLogoutStatus', 'stopping_sra')
			// stt
			await (await STTService.singleton())?.stopCRA()
			
			this.context.commit('setLogoutStatus', 'rxdb')
			/* RxDB */
			const db = await DatabaseService.singleton()
			await db?.onUserLogout()
			
			this.context.commit('setLogoutStatus', 'clearing_user_data')
			// czyszczenie niepotrzebnych danych
			this.context.dispatch('removeCalculatedParams')
			
			this.context.dispatch('CurrentStays/resetSelectedUnitRoom', null, { root: true })
			
			await createEventFromCode('user-logout-success')
			
			this.context.commit('setInstance', null)
			this.context.commit('clearUserData')
			
			this.context.commit('setLogoutStatus', null)
	
			console.debug('[logout] done.')
		} catch (e) {
			this.context.commit('setLogoutStatus', null)
			
			throw e
		}
	}

	@Action({ rawError: true })
	async get_user_data() {
		const user = await $classes.Person.current_user()
		this.context.commit('setInstance', user)
	}

	@Action({ rawError: true })
	initOneSignalSDK() {
		if (
			!this.onesignal_skd_initialized &&
			this.instance &&
			this.instance.role == 'Runner'
		) {
			window.OneSignal = window.OneSignal || []
			window.OneSignal.push(() => {
				window.OneSignal.init({
					appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
					allowLocalhostAsSecureOrigin:
						process.env.NODE_ENV !== 'production',
				})
			})

			console.log('onesignal sdk initialized')

			this.context.commit('setOneSignalInitialized')
		}
	}
	
	@Action({ rawError: true })
	async setOrgUnitsList(assigned_org_units) {
		await this.context.dispatch('requireAuthenticatedClasses')
		await $classes.Person.set_org_unit_list({assigned_org_units: assigned_org_units})
	}
}

export default CurrentUser
