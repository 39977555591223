import { couch_uuid, id, index } from './fields'

import { getAsBool } from '@/functions/env'
const VUE_APP_MODULE_NOTE_SEND_TO_CN_ENABLED = getAsBool('VUE_APP_MODULE_NOTE_SEND_TO_CN_ENABLED')

const getProperties = () => {
	let properties = {
		couch_uuid,
		id: {
			...id,
		},
		stay_id_ref: {
			...id,
			...index,
		},
		hospitalisation_id_ref: {
			...id,
			...index,
		},
		template_remote_id: {
			...id,
		},
		remote_id: { ...id },
		
		creation_date: {
			type: 'string',
		},
		name: {
			type: 'string',
		},
		form: {
			type: ['object', 'null'],
		},
		stay_orgunit_code: {
			type: 'string',
		},
		status: {
			type: 'string',
		},
		action: {
			type: 'string',
		},
		stay_note_uuid: {
			...couch_uuid,
		},
		person_id_ref: {
			...id,
		},
	}
	
	if (!VUE_APP_MODULE_NOTE_SEND_TO_CN_ENABLED) {
		delete properties.stay_note_uuid
	}
	
	return properties
}

export const schema = {
	title: 'threatment request schema',
	description: 'describes a single threatment request',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: getProperties(),
	required: ['couch_uuid'],
}

export default schema
