import moment from 'moment'
import 'moment/locale/pl'
moment.locale('pl')

import { isQuotaExceededError, quotaExceededErrorResolution } from '@/functions/error'
import { keys } from '@/functions/localStorage'
import { getAsInt } from '@/functions/env'
const { saHashTripleKey } = keys

export const keyLastSync = keys.lastSync,
	getOldestSyncInfo = function (collectionName = null) {
		let oldest = null

		if (collectionName)
			oldest = getLastSyncInfoForCollection(collectionName)
		else {
			let timesObj = getLastSyncInfo(),
				times = Object.keys(timesObj || {}).map(
					(collection) => timesObj?.[collection]
				)

			oldest = times?.length > 0 ? Math.min(...times) : null
		}

		const dateNow = new Date().getTime(),
			resetPeroidInMs = getAsInt(
				'VUE_APP_SYNC_RESET_PEROID_IN_MILLISECONDS'
			),
			resyncPeroidInMs = getAsInt(
				'VUE_APP_SYNC_RESYNC_PEROID_IN_MILLISECONDS'
			),
			zwrDedicatedResyncPeroidInMs = getAsInt(
				'VUE_APP_ZWR_DEDICATED_SYNC_RESYNC_PEROID_IN_MILLISECONDS'
			),
			oldestDontExist = oldest === null,
			diffInMs = !oldestDontExist ? dateNow - oldest : Infinity,
			shouldReset = diffInMs >= resetPeroidInMs && !oldestDontExist,
			shoudSync = diffInMs >= resyncPeroidInMs || oldestDontExist,
			shoudZwrDedicatedSync =
				diffInMs >= zwrDedicatedResyncPeroidInMs || oldestDontExist,
			diffHumanized = !oldestDontExist
				? timeHumanize({value: diffInMs})
				: null

		return {
			oldest,
			shouldReset,
			shoudSync,
			shoudZwrDedicatedSync,
			resetPeroidInMs,
			resyncPeroidInMs,
			zwrDedicatedResyncPeroidInMs,
			diffHumanized,
			oldestDontExist,
			diffInMs,
		}
	},
	dateFormatCustom = function (val = null, prop = 'last_seq') {
		if (!val) return null

		const baseLength = '2023-01-27 12:03:18.88284'.length

		if (val?.length > baseLength) val = val?.substring(0, baseLength)

		const m = moment(val)

		if (prop === 'last_seq') {
			// czas + 2
			return m.utcOffset('+02:00').format('YYYY-MM-DD HH:mm:ss')
		}

		return val
	},
	calculateDuration = function (o) {
		const a = o?.start_time,
			b = o?.end_time

		if (a && b) {
			return moment.utc(moment(b).diff(moment(a))).format('mm:ss:SS')
		} else if (a)
			return moment.utc(moment().diff(moment(a))).format('mm:ss:SS')

		return null
	},
	removeLastSyncInfo = function () {
		localStorage.removeItem(keyLastSync)
	},
	getLastSyncInfo = function () {
		return JSON.parse(localStorage.getItem(keyLastSync) || '{}')
	},
	getLastSyncInfoForCollection = function (collectionName) {
		const time = getLastSyncInfo()?.[collectionName]

		return typeof time === 'undefined' ? null : time
	},
	calculateDurationPerDoc = function (o) {
		const a = o?.start_time,
			b = o?.end_time,
			docs_read = o?.docs_read

		if (docs_read)
			if (a && b) {
				return moment
					.utc(moment(b).diff(moment(a)) / docs_read)
					.format('mm:ss:SSS')
			}

		return null
	},
	keyPendingSync = keys.keyPendingSync,
	setPendingSyncTime = function (
		value = new Date().getTime() +
			parseInt(process.env.VUE_APP_SYNC_RETRY_PEROID_IN_MILLISECONDS)
	) {
		console.debug(`[setPendingSyncTime]`, value)
		try {
			localStorage.setItem(keyPendingSync, value)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	},
	getPendingSyncTime = function () {
		console.debug(`[getPendingSyncTime]`)
		if (localStorage.getItem(keyPendingSync))
			return parseInt(localStorage.getItem(keyPendingSync))

		return undefined
	},
	pendingSyncTimeExist = function (value = getPendingSyncTime()) {
		console.debug(`[pendingSyncTimeExist]`, value)
		if (typeof value !== 'undefined') return true
		return false
	},
	pendingSyncTimePassed = function () {
		console.debug(`[pendingSyncTimePassed]`)
		const pendingSyncTime = getPendingSyncTime()

		if (pendingSyncTime && pendingSyncTimeExist(pendingSyncTime))
			if (new Date().getTime() > pendingSyncTime) return true
			
		return false
	},
	resetPendingSyncTime = function () {
		console.debug(`[resetPendingSyncTime]`)
		localStorage.removeItem(keyPendingSync)
	},
	timeHumanize = function ({value=0, unit='milliseconds'}={}) {
		return moment.duration(value, unit).humanize()
	},
	isSyncWhichDisableButtonsIsInProgress = function(code){
		const codes = ['default-full', 'default-partial']
		
		if(Array.isArray(code))
			return codes.some(r=> code.indexOf(r) >= 0)
		else if(typeof code === 'string')
			return codes?.includes(code)
		
		return false
	},
	getKeyLastFullSyncPersonal = () => {
		let userPassHashFromHash = localStorage.getItem(
			saHashTripleKey
		)
		
		if(!userPassHashFromHash) return null
		
		return `${keys.keyLastFullSync}${userPassHashFromHash}`
	},
	setLastFullSync = function (
		value = new Date().getTime()
	) {
		console.debug(`[setLastFullSync]`, value)
		
		let keyLastFullSyncPersonal = getKeyLastFullSyncPersonal()
		
		if(keyLastFullSyncPersonal) {
			try {
				localStorage.setItem(keyLastFullSyncPersonal, value)
			} catch (e) {
				if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
				else throw e
			}
		}
	},
	getLastFullSync = function () {
		console.debug(`[getLastFullSync]`)
		let keyLastFullSyncPersonal = getKeyLastFullSyncPersonal()
		
		if (keyLastFullSyncPersonal && localStorage.getItem(keyLastFullSyncPersonal)){
			const ret = parseInt(localStorage.getItem(keyLastFullSyncPersonal))
			console.debug(`[getLastFullSync]`, ret)
			return ret
		}

		return null
	},
	resetLastFullSync = function () {
		console.debug(`[resetLastFullSync]`)
		let keyLastFullSyncPersonal = getKeyLastFullSyncPersonal()
		localStorage.removeItem(keyLastFullSyncPersonal)
	}

export default getOldestSyncInfo
