import { reloadWindow } from '@/functions/cache.js'

import { clearCachedData, clearAllLocalStorage, keys } from '@/functions/localStorage'

const { promiseAllSynchronous } = require('@/functions/promise.js')

export const resolveError = async function () {
	const error = arguments[0],
		{ message, name } = error

	if (typeof name !== 'undefined') {
		let resolution = []

		// if (name === 'ChunkLoadError') // PT-1167
		// 	resolveByReload = true
		if (message.includes(`Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.`))
			resolution.push('reload')
		else if (message == `Database has a global failure`)
			resolution.push('reload')
		else if (message.includes('Out of memory'))
			resolution = [...resolution, 'clearIndexedDB', 'clearLocalStorage', 'reload']
		else if (message.includes(`InvalidStateError: Failed to execute 'transaction'`))
			resolution.push('reload')
		
		if(resolution?.length > 0){
			const resultionNames = {
				'reload': 'przeładowanie strony',
				'clearIndexedDB': 'czyszczenie IndexedDB',
				'clearLocalStorage': 'czyszczenie localStorage',
			}, resultionActions = {
				'reload': reloadWindow,
				'clearIndexedDB': clearIndexedDB,
				'clearLocalStorage': clearAllLocalStorage,
			}
			
			console.debug(
				`[resolveError] znaleziono rozwiązania dla erroru '${name}' z wiadomością '${message}' - ${
					resolution.map(r => resultionNames[r]).join(', ')
				}`
			)
			
			await promiseAllSynchronous(resolution, async r => {
				console.warn(`[resolveError] rozwiązuję error: ${name} - ${message} - ${resultionNames[r]}`)
				await new Promise(resolve => setTimeout(resolve, 100))
				
				saveErrorResulution({ message, name }, resolution.map(r => ({
					name: resultionNames[r],
				})))
				
				await resultionActions[r]()
			})
		}
	}
}

export const getErrorMessage = function (e) {
	return (
		((e.response || {}).data || {}).message ||
		((e.response || {}).data || {}).error_description ||
		e.message ||
		e
	)
}

export const isQuotaExceededError = function(err) {
	return (
		err instanceof DOMException &&
		// everything except Firefox
		(err.code === 22 ||
			// Firefox
			err.code === 1014 ||
			// test name field too, because code might not be present
			// everything except Firefox
			err.name === 'QuotaExceededError' ||
			// Firefox
			err.name === 'NS_ERROR_DOM_QUOTA_REACHED')
	)
}

export const quotaExceededErrorResolution = function(e) {
	console.warn(e)
	
	// clear paricular keys from local storage
	clearCachedData()
}

export const sanitizeError = function (error={}) {
	const regex = /"password":"([^"]*)"/g
	
	if(error?.config?.data && regex.test(error?.config?.data)){
		error.config.data = error.config.data.replace(regex, '"password":"***"')
	}
	
	return error
}

export const clearIndexedDB = async function () {
	console.warn(`[clearIndexedDB] clearing indexedDB`)
    const databases = await window.indexedDB.databases();
    for (const db of databases) {
        await new Promise((resolve, reject) => {
            const request = window.indexedDB.deleteDatabase(db.name);
            request.onsuccess = () => resolve();
            request.onerror = () => reject();
            request.onblocked = () => console.error(`Database ${db.name} deletion blocked`);
        });
    }
    console.log('[clearIndexedDB] IndexedDB cleared');
}

const saveErrorResulution = function (error, resolution) {
	localStorage.setItem(keys.errorResulution, JSON.stringify({ error, resolution }))
}

export const getErrorResulutionAndRemove = function () {
	const data = localStorage.getItem(keys.errorResulution)
	localStorage.removeItem(keys.errorResulution)
	return JSON.parse(data)
}