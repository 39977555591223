import Vue from 'vue'
import App from './App.vue'
import origBulkDocs from './services/PouchBulkPostPatch' // musi być zaimportowne przed rxdb
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Error500 from './components/Error500.vue'
import i18n from './i18n'

import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'

import VueNotification from '@kugatsu/vuenotification'

import colors from '@/assets/scss/colors.scss'

import AsyncComputed from 'vue-async-computed'

console.log('origBulkDocs name:', origBulkDocs.name)

// https://github.com/kugatsu765/vueNotification

Vue.use(VueNotification, {
	timer: 20,
	position: 'bottomLeft',
	error: {
		background: colors.error,
		color: '#fff',
		showLeftIcn: false,
	},
	primary: {
		background: colors.primary,
		color: '#fff',
	},
	success: {
		background: colors.success,
		color: '#fff',
	},
	warning: {
		background: colors.warning,
		color: '#fff',
	},
})

Vue.use(Donut)

Vue.use(AsyncComputed)

import DatabaseService from '@/services/Database.service.js'
import STTService from '@/services/STT.service.js'

import Classes from './framework/classes.js'
import './registerServiceWorker'

let $classes = new Classes(process.env.VUE_APP_API_ROOT)

Vue.config.productionTip = true
Vue.config.devtools = true
Vue.config.performance = true

Vue.prototype.$devDateTime = process.env.VUE_APP_DEV_DATE
	? process.env.VUE_APP_DEV_DATE
	: null // process.env.NODE_ENV === 'development' &&

// PD-805
require('events').EventEmitter.defaultMaxListeners = 100;

// const vm = new Vue({
//   router,
//   i18n,
//   store,
//   provide: {$classes, $db},
//   vuetify,
//   render: h => h(App)
// });

// vm.$mount('#app');

import { resolveError } from '@/functions/error.js'
import { errorToReport } from '@/functions/reportProblem.js'
import { getAsBool } from '@/functions/env'

const debugEnabled = getAsBool('VUE_APP_STORE_CONSOLE_LOGS')

if (debugEnabled) {
	if (console.storedLogs === undefined) {
		console.storedLogs = []
		console.defaultError = console.error.bind(console) //non storing console.error
		console.error = async function () {
			console.storedLogs.push({
				type: 'error',
				datetime: Date().toLocaleString(),
				value: Array.from(arguments),
			})
			
			await errorToReport(arguments[0])
			resolveError.apply(null, arguments)

			console.defaultError.apply(console, arguments)
		}
		console.defaultWarn = console.warn.bind(console) //non storing console.warn
		console.warn = function () {
			console.storedLogs.push({
				type: 'warn',
				datetime: Date().toLocaleString(),
				value: Array.from(arguments),
			})
			console.defaultWarn.apply(console, arguments)
		}
		console.defaultLog = console.log.bind(console)
		console.info = function () {
			console.storedLogs.push({
				type: 'info',
				datetime: Date().toLocaleString(),
				value: Array.from(arguments),
			})
			console.defaultLog.apply(console, arguments)
		}
		console.defaultDebug = console.debug.bind(console)
		console.debug = function () {
			console.storedLogs.push({
				type: 'debug',
				datetime: Date().toLocaleString(),
				value: Array.from(arguments),
			})
			console.defaultDebug.apply(console, arguments)
		}
	} else {
		console.error('console.storedLogs has already been defined')
	}
} else {
	console.defaultError = console.error.bind(console)
	console.error = async function () {
		await errorToReport(arguments[0])
			
		resolveError.apply(null, arguments)
		console.defaultError.apply(console, arguments)
	}
}

let vm = null

console.log(
	// eslint-disable-next-line max-len
	`Repository\nVERSION: ${process.env.VUE_APP_VERSION},\nCOMMIT_HASH: ${process.env.VUE_APP_COMMIT_HASH},\nLAST_COMMIT_DATETIME: ${process.env.VUE_APP_LAST_COMMIT_DATETIME},\nBRANCH: ${process.env.VUE_APP_BRANCH}`
)
console.debug('env', process?.env)
console.debug('userAgent', navigator?.userAgent)

import { shouldClearCache } from '@/functions/cache.js'

shouldClearCache()

import { getOldestSyncInfo } from '@/functions/sync'
import { getErrorMessage } from '@/functions/error.js'

async function bootstrap() {
	try {
		const oldestSyncInfo = getOldestSyncInfo(),
			{ diffHumanized, oldestDontExist } = oldestSyncInfo

		if (!oldestDontExist)
			console.log(
				`Najstarsza synchronizacja odbyła się ${diffHumanized} temu.`
			)

		// try {
		// 	await $classes.loadPublic()
		// } catch (e) {
		// 	console.log({ ...e })
		// }
		
		const $DatabaseService = await DatabaseService.singleton()
		// const $STTService = await STTService.singleton()
		let $STTService
		
		try {
			$STTService = await STTService.singleton()
		} catch (e) {
			console.warn(e)
		}
		

		// window.$DatabaseService = $DatabaseService
		// window.$db = $DatabaseService?.db

		vm = new Vue({
			router,
			i18n,
			store,
			provide: { $classes, $DatabaseService, $STTService },
			vuetify,
			render: (h) => h(App),
		})

		vm.$mount('#app')
	} catch (e) {
		console.log({ ...e })
		const msg = getErrorMessage(e)
		new Vue({
			i18n,
			vuetify,
			components: { Error500 },
			render: (h) => h(Error500, { attrs: { msg } }),
		}).$mount('#app')
	}
}

bootstrap()

window.$classes = $classes
window.$vm = vm

export { $classes, vm }
